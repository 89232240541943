import React from 'react'
import PropTypes from 'prop-types'
import theme from '../../config/theme'
import Color from 'color'
import styled from 'styled-components'
import { FaVideo, FaTv, FaFileAlt } from 'react-icons/fa'

const _PresentationsIcons = ({
  videoId,
  slideDeck,
  paperUrl,
  ...props
}) =>
  <span {...props}>
    {videoId && <FaVideo key="video" title="Has video" />}
    {slideDeck && <FaTv key="slides" title="Has slide deck"/>}
    {paperUrl && <FaFileAlt key="paper" title="Has publication"/>}
  </span>

_PresentationsIcons.propTypes = {
  videoId: PropTypes.any,
  slideDeck: PropTypes.any,
  paperUrl: PropTypes.any,
}

const PresentationsIcons = styled(_PresentationsIcons)`
  color: ${({ color }) => Color(color || theme.colors.dark.color).alpha(0.5).hsl().string()};
  & > * {
    margin: 0;
    margin-right: 0.5em;
    :first-child {
      margin-left: 0.5em;
    }
    vertical-align: middle;
  }
`

PresentationsIcons.propTypes = {
  ..._PresentationsIcons.propTypes,
  color: PropTypes.string
}

export default PresentationsIcons
