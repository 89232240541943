import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '@components/Layout'
import Hero from '@components/Hero'
import PresentationsIcons from '@components/PresentationsIcons'
import styled from 'styled-components'
import theme, { makeGradient } from '@config/theme';
import { Link, graphql, useStaticQuery } from 'gatsby';

const _PresentationLI = ({ className, data }) =>
  <Link to={`/presentations/${data.slug}`} className={className}>
    <span className="title">
      {data.title}
      <PresentationsIcons {...data} />
    </span>
    <span className="date">{data.date}</span>
  </Link>

_PresentationLI.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired
}

const PresentationLI = styled(_PresentationLI)`
  /* self styles */
  padding: 1rem;
  :not(:last-child) {
    border-bottom: 1px rgba(0,0,0,20%) solid;
  }
  background-color: rgba(230,230,230,0%);
  :hover {
    background-color: rgba(230,230,230,100%);
  }
  color: ${theme.colors.dark.color};
  text-decoration: none;
  transition: all 0.2s;

  /* children styles */
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  & > * {
    max-width: 100%;
  }
  & > .title {
    flex: 1 0 auto;
    font-weight: bold;
  }
  & > .date {
    flex: 0 0 4rem;
    max-width: 4rem;
    color: hsla(0, 0%, 25%, 0.75);
  }
`

const _PresentationsList = ({ className, ...props }) => {
  const {
    allPresentationsYaml: {
      edges
    }
  } = useStaticQuery(graphql`
      query PresentationsQuery {
        allPresentationsYaml (
          sort: {
            fields: [sortPriority, date, title]
            order: [ASC, DESC, ASC]
          }
        ) {
          edges {
            node {
              id
              slug
              title
              date
              videoId
              slideDeck
              paperUrl
            }
          }
        }
      }
    `
  )

  const presentations = edges.map(n => n.node)
  return (
    <div className={className}>
      {presentations.map(p =>
        <PresentationLI data={p} key={p.id} />
      )}
    </div>
  )
}

const PresentationsList = styled(_PresentationsList)`
  max-width: 960px;
  margin: 2rem auto;
  padding: 1rem;
`

const Presentations = () =>
  <Layout>
    <Hero><h1>Presentations and Publications</h1></Hero>
    <PresentationsList />
  </Layout>

export default Presentations
